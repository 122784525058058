import React from "react"
import { Link } from "gatsby"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"
import SplitPanel from "../components/pages/SplitPanel"
import CenterText from "../components/pages/CenterText"
import CategoryFooter from "../components/pages/CategoryFooter"

// Data
import content from "../pages/static-pages/index.yaml"

class Index extends React.Component {
  // TEST COMMENT
  state = { sliderOptions: "" }

  componentDidMount = () => {
    if (typeof window !== "undefined") {
      var win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName("body")[0],
        x = win.innerWidth || docElem.clientWidth || body.clientWidth

      if (x >= 640) {
        this.setState({
          sliderOptions:
            "draggable: true; sets: true; autoplay: true; autoplayInterval: 3000;",
        })
      } else {
        this.setState({
          sliderOptions:
            "center: true;infinite: true; autoplay: true; autoplayInterval: 3000;",
        })
      }
    }
  }

  render() {
    return (
      <Layout>
        <SEO
          title={content.title}
          description={content.seo_description}
          image={content.seo_image}
        />

        {/* Home Feature */}
        <div
          className="feature-banner home uk-flex uk-flex-center uk-background-cover uk-light uk-preserve-color"
          data-src={content.feature.image.url}
          uk-img=""
        >
          <div className="uk-width-1-1 uk-flex uk-flex-center">
            <div className="uk-text-center uk-padding uk-margin-auto-vertical">
              <h5
                uk-icon={
                  "icon: " +
                  content.feature.icon.type +
                  "; ratio: " +
                  content.feature.icon.ratio +
                  ";"
                }
              ></h5>
              <h1
                h1
                className="uk-margin-small uk-text-bold uk-width-3-5 uk-margin-auto"
              >
                {content.feature.title}
              </h1>
              <h5 className="uk-margin-remove-top">
                {content.feature.tagline}
              </h5>
              <Link
                to={content.feature.link.url}
                className="uk-button uk-button-large uk-button-primary uk-border-pill uk-margin-bottom"
              >
                {content.feature.link.btnText}
              </Link>
            </div>
          </div>
        </div>

        {/* CTA Banner 1 */}
        <div className="uk-container uk-container-expand-right">
          <div className="uk-grid uk-flex uk-flex-middle">
            <div className="uk-width-1-2@s uk-padding">
              <h3 className="uk-width-5-5@s uk-width-2-5@m uk-margin-large-left@m uk-heading uk-h2 uk-text-bold">
                {content.install.title}
              </h3>
              <div className="uk-width-5-5@s uk-width-3-5@m uk-margin-large-left@m uk-margin">
                <p className="uk-text-p-large uk-width-3-5@xl">
                  {content.install.desc}
                </p>
              </div>
              {content.install.link && (
                <Link
                  target="_blank"
                  to={content.install.link}
                  className="uk-button uk-button-large uk-button-primary uk-border-pill uk-margin-bottom"
                >
                  {content.install.btnText}
                </Link>
              )}
            </div>
            <div className="uk-width-1-2@s">
              <div className="uk-container-item-padding-remove-right uk-height-large">
                <div
                  className="uk-background-cover uk-background-center-left uk-height-1-1 uk-height-large"
                  data-src={content.install.image.url}
                  uk-img=""
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-section-secondary uk-dark bg-logo-detail">
          <div className="uk-container uk-container-expand">
            {/* Icons */}
            <div className="uk-width-1-1 uk-width-2-3@l uk-margin-auto uk-margin-medium-bottom uk-text-center">
              <h3 className="uk-margin-small-bottom uk-text-bold uk-width-2-3@m uk-margin-auto uk-text-center">
                {content.benefitTitle}
              </h3>
              <span className="uk-text-small">{content.benefitTagLine}</span>
              <div
                className="uk-position-relative uk-visible-toggle uk-margin-large-top"
                tabIndex="-1"
                uk-slider={this.state.sliderOptions}
              >
                <div className="uk-slider-items icon-slider">
                  {content.benefits.map((benefit) => (
                    <div
                      className="uk-width-3-5 uk-width-1-2@s uk-width-1-3@m"
                      key={benefit.title}
                    >
                      <span
                        className="uk-flex uk-flex-center uk-margin-small uk-margin-bottom uk-text-primary"
                        uk-icon={
                          "icon: " +
                          benefit.icon.type +
                          "; ratio: " +
                          benefit.icon.ratio +
                          ";"
                        }
                      ></span>
                      <p className="uk-text-primary uk-text uk-width-4-5 uk-width-3-5@m uk-margin-auto">
                        {benefit.title}
                      </p>
                    </div>
                  ))}
                </div>
                <ul className="uk-slider-nav uk-dotnav uk-margin uk-flex-center uk-hidden@m uk-width-1-1"></ul>
              </div>
            </div>
          </div>
        </div>
        {/* 5 reasons */}
        <div className="uk-section uk-section-default uk-dark bg-logo-detail">
          <div className="uk-container uk-container-expand">
            <div className="uk-width-1-1 uk-width-2-3@l uk-margin-auto uk-text-center">
              <h3 className="uk-margin-bottom uk-margin-top uk-text-bold">
                {content.reasonsTitle}
              </h3>
              <div
                className="uk-position-relative uk-visible-toggle uk-margin-medium-top"
                tabIndex="-1"
                uk-slider={this.state.sliderOptions}
              >
                <div className="uk-slider-items icon-slider">
                  {content.reasons.map((reason) => (
                    <div
                      className="uk-width-4-5 uk-width-1-2@s uk-width-1-5@m"
                      key={reason.title}
                    >
                      <span
                        className="uk-flex uk-flex-center uk-margin-small uk-margin-bottom uk-text-primary"
                        uk-icon={
                          "icon:" +
                          reason.icon.type +
                          "; ratio: " +
                          reason.icon.ratio +
                          ";"
                        }
                      ></span>
                      <p className="uk-text-primary uk-text uk-width-4-5 uk-margin-auto">
                        {reason.title}
                      </p>
                    </div>
                  ))}
                </div>
                <ul className="uk-slider-nav uk-dotnav uk-margin uk-flex-center uk-hidden@m uk-width-1-1"></ul>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-section uk-section-secondary uk-dark bg-logo-detail">
          <div className="uk-container">
            <SplitPanel
              title={content.about.title}
              desc={content.about.desc}
              link={content.about.link}
              btn={content.about.btnText}
              image={content.about.image.url}
              mobileImage={content.about.image.mobile}
              color="light"
              align="left"
              margin={true}
            />

            {/* Request an installer */}
            <div className="uk-width-3-5@l uk-margin-auto uk-margin-medium-bottom uk-text-center">
              <h3> {content.installer.title} </h3>
              <p> {content.installer.desc} </p>
              <Link
                to={content.installer.url}
                className="uk-button uk-button-primary uk-border-pill"
              >
                {content.installer.btnText}
              </Link>
            </div>
          </div>
        </div>

        {/* Testimonials
        <div className="uk-section uk-section-default">
          <CenterText
            title={content.testimonialsTitle}
            data={content.testimonial}
            testimonial={true}
          />
        </div>*/}

        {/* Panels */}
        <div className="uk-section">
          <div className="uk-container">
            <div className="uk-section uk-section-small uk-margin-small uk-margin-small-top uk-text-left">
              <h2 className="uk-text-center ">{content.panelsTitle}</h2>
              <div
                className="uk-container uk-margin-large-top"
                tabIndex="-1"
                uk-slider={this.state.sliderOptions}
              >
                <div className="uk-slider-items image-slider">
                  {content.panels.map((item) => (
                    <a
                      href={item.link.url}
                      key={item.link.url}
                      className="uk-padding-small uk-width-1-3 uk-width-1-2@s uk-width-1-3@m"
                    >
                      <img
                        className="uk-flex uk-flex-center uk-margin-small uk-margin-bottom uk-text-primary"
                        src={item.image.url}
                        alt={item.title}
                      />
                      <h5 className="uk-text-primary uk-width-1-1 uk-width-1-1@m uk-margin-small-top">
                        {item.title}
                      </h5>
                    </a>
                  ))}
                </div>
                <ul className="uk-slider-nav uk-dotnav uk-margin uk-flex-center uk-width-1-1"></ul>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-section-small">
          <div className="uk-container">
            <div className="uk-width-3-4 uk-width-2-3@s uk-width-1-2@m">
              <small className="uk-text-meta">{content.description} </small>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index
